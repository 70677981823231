import React from 'react'
import { graphql, Link } from 'gatsby';
import { Layout } from '../components';
import { Site404Props } from '../types/blog.types';

export default class NotFound extends React.Component<Site404Props, {}> {
  render() {
    const { title, tag, author, copyright, socials } = this.props.data.site.siteMetadata;

    return (
      <Layout 
        title={title}
        tag={tag}
        introduction={author.about}
        about={author.about}
        copyright={copyright}
        socials={socials}
        >   
        <div>
          <h1>Not Found</h1>
          <p>You just hit a route that doesn&#39;t exist...</p> 
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        tag
        subtitle
        socials {
          link
          name
        }
        siteUrl
        seo {
          description
          title
          titleTemplate
          twitterUsername
          url
        }
        copyright
        author {
          bio
          name
          about
        }
      }
    }
  }`